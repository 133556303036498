const routerConocimientos = [
    {
      path: "/conocimientos",
      name: "conocimientos",
      component: () =>
        import(
          /* webpackChunkName: "conocimientos" */ "./Conocimientos"
        ),
        //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
  ];

  export default routerConocimientos;
