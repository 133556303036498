export default [
  {
    path: "/candidatos",
    name: "candidatos",
    component: () =>
      import(/* webpackChunkName: "candidatosList" */ "./candidatos.vue"),
    //meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
  {
    path: "/candidato/:id",
    name: "curriculum",
    component: () =>
      import(/* webpackChunkName: "candidatosList" */ "./curriculumView.vue"),
    //meta: { requiresAuth: true } // Esta ruta requiere autenticación
  },
];
