import Vue from "vue";
import VueRouter from "vue-router";
import rolesRouter from "../views/roles/rolesRouter";
import profilesRouter from "../views/profiles/profilesRouter";
import usersRouter from "../views/users/userRouter";
import pathsRouter from "../views/paths/pathRouter";
import routerCargos from "../views/puestos/puestosRouter";
import routerFormacion from "../views/formacion/formacionRouter";
import capacidadesRouter from "../views/capacidades/capacidadesRouter";
import areasRouter from "../views/areas_interes/areasRouter";
import routerConocimientos from "../views/conocimientos/conocimientoRouter";
import routerPalabrasClaves from "../views/palabras_claves/palabrasClavesRouter";
import ofertasRouter from "../views/ofertas/routes/ofertasRouter";
import candidatosRouter from "../views/candidatos/candidatosRouter";
import routerEmpleo from "../views/card_puesto/cardRouter";
import preguntasFrecuentesRouter from "../views/preguntas_frecuentes/preguntasFrecuentesRouter";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "layout" */ "../views/layout"),
    children: [
      {
        path: "/",
        name: "dashboard",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/dashboard"),
      },
      {
        path: "profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profiles" */ "../views/profile"),
      },
      ...rolesRouter,
      ...profilesRouter,
      ...usersRouter,
      ...pathsRouter,
      ...routerCargos,
      ...routerFormacion,
      ...capacidadesRouter,
      ...areasRouter,
      ...routerConocimientos,
      ...routerPalabrasClaves,
      ...ofertasRouter,
      ...candidatosRouter,
      ...routerEmpleo,
      ...preguntasFrecuentesRouter,
      {
        path: "/security",
        name: "security",
        component: () =>
          import(/* webpackChunkName: "qr" */ "../views/auth/security"),
      },
      {
        path: "/example",
        name: "example",
        component: () =>
          import(/* webpackChunkName: "example" */ "../views/example.vue"),
      },
    ],
  },
  {
    path: '/seleccionar-puesto',
    name: 'seleccionar-puesto',
    component: () =>
        import(/* webpackChunkName: "seleccion-puesto" */ '../views/auth/card'),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/login"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "recuperarPassword" */ "../views/auth/recoverPassword"
      ),
  },
  {
    path: "/reset-password/:id",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/auth/resetPassword"
      ),
  },
  {
    path: "/verify-mail/:token",
    name: "verifyMail",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/verifyMail"),
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () =>
      import(/* webpackChunkName: "forbidden" */ "../views/forbidden"),
  },
  {
    path: "/autenticacionqr",
    name: "2fa",
    component: () => import(/* webpackChunkName: "qr" */ "../views/auth/2fa"),
  },
  {
    path: '*',
    redirect: '/forbidden'
  }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// router.beforeEach(async (to, from, next) => {
//   if (to.fullPath === "/") {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       next("/login");
//       return;
//     }
//   }
//   if (await Vue.prototype.canNext(to)) {
//     next();
//   } else {
//     next("/forbidden");
//   }
// });

export default router;
