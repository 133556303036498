export default [
    {
      path: "preguntas",
      name: "preguntas",
      component: () =>
        import(/* webpackChunkName: "palabras-clavesList" */ "./preguntas.vue"),
      //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
    {
      path: "agregar-preguntas",
      name: "agregar-preguntas",
      component: () =>
        import(/* webpackChunkName: "palabras-clavesList" */ "./agregar_preguntas.vue"),
      //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
    {
      path: "editar-preguntas/:id?",
      name: "editar-preguntas",
      props: route => ({
        isEdit: true, // Add any other props here
      }),
      component: () =>
        import(/* webpackChunkName: "palabras-clavesList" */ "./agregar_preguntas.vue"),
      //meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
  ];
  