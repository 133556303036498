const routerFormacion = [
    {
      path: "/formacion",
      name: "formacion",
      component: () =>
        import(
          /* webpackChunkName: "formacion" */ "./formacion"
        ),
       // meta: { requiresAuth: true } // Esta ruta requiere autenticación
    },
  ];

  export default routerFormacion;
